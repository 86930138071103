import React from 'react'

function ArrowTop() {
    return (
        <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="23.1279" fill="#EEE3D3" />
            <path d="M22.9945 34.5639C23.9271 34.5639 24.5698 33.9447 24.5698 33.022V18.6354L24.4564 16.0009L27.4432 19.2546L30.2914 21.9741C30.5813 22.2412 30.9593 22.4233 31.413 22.4233C32.27 22.4233 32.9128 21.8163 32.9128 20.9664C32.9128 20.5658 32.7489 20.1894 32.4213 19.8738L24.1665 11.9095C23.8641 11.606 23.423 11.436 22.9945 11.436C22.566 11.436 22.1375 11.606 21.8351 11.9095L13.5804 19.8738C13.2527 20.1894 13.0889 20.5658 13.0889 20.9664C13.0889 21.8163 13.7316 22.4233 14.5886 22.4233C15.0423 22.4233 15.4203 22.2412 15.7102 21.9741L18.5458 19.2546L21.5326 16.0009L21.4318 18.6354V33.022C21.4318 33.9447 22.0619 34.5639 22.9945 34.5639Z" fill="black" />
        </svg>

    )
}

export default ArrowTop