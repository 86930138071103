export const studies = [
    {"Name": "Flint Money","Role":"Product designer at Flint, a Coinbase-sequoia-based startup. Instrumental in reimagining the experience of buying and owning cryptocurrency through a design perspective, tasked with recruiting quality talent across the globe, and built a library of design patterns and best practices that aid interaction between the crypto community and developers.", "Duration":"March 20, 2022 - August 3, 2022", "Stakeholder":"Flint", "Author":"Rahul Kumar", "About" : "Flint is a global crypto investment app, which goes beyond just buying & selling coins to offer a range of passive investment assets to its users built on top of crypto. On Flint, you can earn stable returns on multiple cryptocurrencies, without locking them in. At Flint, we do things a little differently than other companies in the crypto industry. From our compliance-ready approach to our industry-leading talent and backing of industry’s best investors, we are building Flint with a craft better than the market standards. Flint also has a top-tier risk management process to reduce business risk exposure for you.",
    "VisionHeadline":"We want to be the go-to app for everyone’s financial needs in the crypto",
    "Vision":"Crypto is way more efficient, convenient and reliable than fiat money. As more individuals experience the benefits of crypto, and start adopting it, we will have institutions and governments accommodating it as well. Very soon, we will all become crypto-natives. And when that happens, we will need financial products which are designed for cryptocurrencies.",
    "Vision2": "That’s where we come in. We are building those crypto financial services which cater to all your needs; from payments to investing.",
    "Solution":"Make Crypto investing user friendly and less intimidating",
    "heroImage": "../Assets/Flint.mov",
    "Heading":"Making crypto Investing less intimidating and user friendly",
    "Background":"As a general rule, one should follow an investment strategy that meets the needs of their investment objectives, risk tolerance and time horizon. When it comes to cryptocurrency trading, you need to take into consideration your current knowledge of the market. Following some simple steps can help with investing in cryptocurrency as they would help you to remain calm during market volatility and find out how to invest properly.",
    "Problem": "Although cryptocurrency has a lot of investment potential, due to the complexity normal users are not able to onboard themselves. An ecosystem is required where someone can effectively get a detailed look at the mechanics behind it, be it through personal or business use.",
    "Opportunity":"Flint generates returns on alt coins by making use of strategies that only a few crypto-native folks are able to access today. You can directly deposit stable coins, or you can deposit fiat funds which then gets converted to stable coins. Flint then diversifies these deposited funds across various institutional borrowers and de-fi protocols to reduce risks and maximize returns. ",
    "Challenge":"How might we help investors confidently and effortlessly invest in cryptocurrency on Flint?",
    "ResearchHead":"Design goal for Crypto Currency",
    "Research1":"Through our research, we have discovered that there are lots of issues with cryptocurrency user experience. The problems include high commissions and slow transaction speed, as well as a lack of clear communication in address name.. This detracts from the potential user experience and makes cryptocurrency investing more difficult than it should be.",
    "ResearchCont":"We are currently living in a distributed network that requires a high level of attention and care to maintain it. The current state of blockchain causes it to be used by a small group of users, who are forced to deal with relatively high entry barriers and numerous problems than meeting user requirements. Keeping these pain points in mind, we came up with four key design goals to follow during this redesign process: Discoverability, Minimalism, Education, and Security.",
    "Head1" : "Discoverability",
    "Head2" : "Minimalism",
    "Head3" : "Education",
    "Head4" : "Security",
    "Discover1":"New transactional users can actually see the main product offering in the home page itself for easy navigation",
    "Discover2":"Helping users create a visual mental model for all our product lines with the help of information architecture.",
    "Discover3":"Our interface is designed to present the most relevant and up-to-date information from the crypto world to provide you with the option to explore cryptocurrencies in real time",
    "Minimal1" :"Minimal designs enable investor to make their decision in a much simple and faster way. They can clearly see what they are investing in, which helps them make a better decision on the investment.",
    "Minimal2":"The guideline was designed and built to emphasize the clear proximity and aesthetics of the visuals, in addition to other important aspects such as brand consistency, color scheme and scale.",
    "Minimal3":"Principles focused on choosing less and focusing on those things that matter most.",
    "Education1":"The News & Learn section introduces an increasing variety of new information sources, allowing investors to make informed decision while they are trading.",
    "Education2":"With the aid of deposit guide, you can make crypto transfers very easily by selecting the most suitable option and without any difficulty.",
    "Education3":"Education via tooltips and bottom sheet that explain jargon like “gas fees” and “staking”.",
    "Security1":"Enabling two factor authentication, so we know it’s you when you initiate a transfer, and transaction checks to understand better the risk of where coins are being sent.",
    "Security2":"To reduce the risk of phishing and identity theft in these times, we’ve created different social proofing touch points in the user journey to ensure trust and security.",
    "Security3":'Always make sure to obtain consent for acknowledgment before you start your investment.',
    "Reflection1":"How did I feel working on the product?",
    "Reflection1Text":"I learned a lot about the opportunities in cryptocurrency and passive income. Being with people who are interested in learning about this new way to make money was great. I enjoyed the experience tremendously, especially since it was such a new concept for me."
},
    {"Name": "Tournafest - One Stop Destination for Gamers",
    "Role":"My responsibilities are to ensure the design the user experience for an esport application that handles all matters pertaining to events and tournaments. This includes proper notifications, exact start times, live streams of matches, stats and scores.", "Duration":"March 07, 2021 - August 13, 2021", "Stakeholder":"Tournafest", "Author":"Rahul Kumar",
    "About" : "Tournafest® is the one-stop destination created by the gamers for the gamers. We are reinventing the Esports ecosystem around us with a seamless and motivating platform where gamers can enhance their skills with a network alike. With Tournafest, every gamer is a professional, and their talent gets the recognition they deserve. ",
    "VisionHeadline":"We want to be here to empower and nurture the Esports ecosystem",
    "Vision":"We are a purpose-driven gaming brand which is here to empower and nurture the Esports ecosystem. In the near future we see ourselves as an established entity which is taking charge of mentoring dedicated players. Our daily activities always incorporate values of Collaboration, Exploration, Dedication towards building a better Esports environment. Our values ensure that we work as a well-lubricated team and fulfil a gamer's daily needs.",
    "Solution":"We want to be here to empower and nurture the Esports ecosystem",
    // "Background":"As a general rule, one should follow an investment strategy that meets the needs of their investment objectives, risk tolerance and time horizon. When it comes to cryptocurrency trading, you need to take into consideration your current knowledge of the market. Following some simple steps can help with investing in cryptocurrency as they would help you to remain calm during market volatility and find out how to invest properly.",
    "Problem": "There is no seamless way for the organizer to create a tournament and for gamers there is no central place to register for a tournament with no registration fees. This is one of the problems that we want to solve in Tournafest,",
    "Opportunity":"Our mission is to provide a simplified and ace gaming platform for gamers. We aim at creating a gaming ecosystem in which players can play, compete and grow together. We believe in providing access to mentorship as well as an unlimited fun experience for all the gamers out there. ",
    "Challenge":"How we created a one stop destination for gamers and organisers?",
    "ResearchHead":"Design goal for MVP",
    "Heading":"A one stop destination for gamers and organisers",
    "image1": "../Assets/designGoals.png",
    "image2": "../Assets/flows.png",
    "Link": "https://www.figma.com/file/G7BOCdtGOaTnSTWVZH5nd6/Creating-Flows?node-id=0%3A1&t=n14yB2QPrsYl8Vfl-1",
    "image3": "../Assets/visual.png",
    "Research1":"In the course of our research, we studied the needs of both the persona of gamers and organizers, and found that it is difficult for new gamers to get their competitive careers started. We started planning an MVP for their exact demand, where users participate in a tournament in 5 mins and similar for organizers to create a tournament. Based on test results, we improved the user experience and created more complex features.",
    "ResearchHead2":"User Flow and Wireframes",
    "Research2":"Creating the user flow for an e-sport product is a combination of creating an end to end solution for players, coaches, and tournament organizers. The features include creating a module for guild creation, a feed viewer, along with tournament creation and management.",
    "ResearchHead3":"Visual design",
    "Research3":"For our new livery, we were strict about color and dimension. Color is the visual language of games, so it's very important to use it correctly. We chose aubergine, keeping it modern with a solid line highlighting all main details, but playing around with the line thickness and width will help you find your own unique style.",
    "Reflection1":"Iterations act like golden bullet",
    "Reflection1Text":"I believe in iterating and it is something that was consistent in my work. As I’ve progressed throughout my design journey with Arun in Tournafest, I observed this practice of working on the basis of user feedback and data analysis. The recent course has been a significant game changer for me in every aspect like how I look at projects, team dynamics, execution processes etc.",
    "Features" : true,
    "FeatureHead" : "Tournament and Guild",
    "Feature1" : "Organizers can create their own tournaments and invite gamers to join. Gamers participating in the tournament receive points for their performance, and the best ones get recognized and rewarded. Organizers can create their own guilds, which acts as a community of their own with moderators controlling the chat environment.",
    "FeatureHead2" : "Feed",
    "Feature2" : "Our feed is designed for e-sport gamers and the latest update and sharing their victories! We curate the most interesting game, news and provide a platform where they can interact with others who share a similar passion.",
    "FeatureHead3" : "Leaderboard",
    "Feature3" : "Leaderboard is a simple, yet effective tool for tracking your progress on the platform. It provides you with the ability to track your performance on various levels like game type and guild. This let's you see where you fit in and gives bragging rights over your friends!",

},
]