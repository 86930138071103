import React from 'react'

function Linkedin() {
    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5 0C10.0735 0 0 10.0735 0 22.5C0 34.9265 10.0735 45 22.5 45C34.9265 45 45.0001 34.9265 45.0001 22.5C45 10.0735 34.9263 0 22.5 0ZM16.497 33.3777H11.5569V17.4177H16.497V33.3777ZM14.0034 15.3276C12.39 15.3276 11.082 14.009 11.082 12.3827C11.082 10.7562 12.3902 9.43766 14.0034 9.43766C15.6166 9.43766 16.9245 10.7562 16.9245 12.3827C16.9246 14.0091 15.6167 15.3276 14.0034 15.3276ZM35.01 33.3777H30.0938V25C30.0938 22.7022 29.221 21.4196 27.4041 21.4196C25.4267 21.4196 24.3937 22.7555 24.3937 25V33.3777H19.6556V17.4177H24.3937V19.5672C24.3937 19.5672 25.819 16.9308 29.2031 16.9308C32.5874 16.9308 35.0101 18.9972 35.0101 23.272L35.01 33.3777Z" fill="#EEE3D3" />
        </svg>

    )
}

export default Linkedin