import React from 'react'

function MotionSVG() {
    return (
        <svg width="53" height="51" viewBox="0 0 53 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_415_18536)">
                <path d="M18.7 1.05078H45.3001C49.5438 1.05078 53.0001 4.507 53.0001 8.75081V34.3008C53.0001 38.5446 49.5439 42.0009 45.3001 42.0009H18.7C14.4563 42.0009 11 38.5446 11 34.3008V8.75081C11.0001 4.507 14.4563 1.05078 18.7 1.05078Z" fill="#D291FF" />
                <path d="M19.05 2.7998H44.95C48.45 2.7998 51.25 5.59978 51.25 9.09981V33.9498C51.25 37.4498 48.45 40.2498 44.95 40.2498H19.05C15.55 40.2498 12.75 37.4498 12.75 33.9498V9.09981C12.75 5.59986 15.55 2.7998 19.05 2.7998Z" fill="#1F0040" />
                <path d="M23.2132 25.6318L21.8205 30.8544C21.8205 30.9937 21.7508 31.0634 21.5419 31.0634H18.9654C18.8261 31.0634 18.7565 30.9937 18.8262 30.7848L23.7702 13.5154C23.8789 13.0596 23.9257 12.5911 23.9095 12.1227C23.9095 11.9834 23.9791 11.9834 24.0488 11.9834H27.6698C27.8091 11.9834 27.8091 11.9834 27.8787 12.1227L33.4494 30.8371C33.4494 30.9763 33.4494 31.046 33.3102 31.046H30.4551C30.346 31.044 30.2437 30.9928 30.1766 30.9067L28.7838 25.6318H23.2132ZM28.0876 22.8465C27.6002 20.8967 26.4164 16.7187 25.9986 14.6296C25.6505 16.649 24.6929 20.1307 23.9792 22.8465H28.0876ZM37.4187 24.5873C37.4187 26.9549 38.6025 28.5565 41.179 28.5565C42.1546 28.5853 43.1243 28.3949 44.0166 27.9994C44.1559 27.9298 44.2256 27.9994 44.2256 28.1387V30.3148C44.223 30.4439 44.1736 30.5677 44.0863 30.6629C43.1811 31.1503 41.9973 31.2896 40.6045 31.2896C36.0087 31.2896 34.2678 27.8775 34.2678 24.1172C34.2678 20.0088 36.4439 16.6663 40.1171 16.6663C43.8774 16.6663 45.2004 19.8695 45.2004 22.446C45.2131 23.0526 45.1664 23.659 45.0611 24.2565C45.0567 24.3508 44.9813 24.4262 44.8871 24.4306C43.9167 24.5461 42.9394 24.5927 41.9624 24.5699L37.4187 24.5873ZM40.97 22.2197H42.2757V21.9412C42.2757 20.9488 41.8056 19.208 39.9951 19.208C38.3239 19.208 37.6275 20.6703 37.4186 22.2023L40.97 22.2197Z" fill="white" />
                <path opacity="0.15" d="M23.2132 25.6318L21.8205 30.8544C21.8205 30.9937 21.7508 31.0634 21.5419 31.0634H18.9654C18.8261 31.0634 18.7565 30.9937 18.8262 30.7848L23.7702 13.5154C23.8789 13.0596 23.9257 12.5911 23.9095 12.1227C23.9095 11.9834 23.9791 11.9834 24.0488 11.9834H27.6698C27.8091 11.9834 27.8091 11.9834 27.8787 12.1227L33.4494 30.8371C33.4494 30.9763 33.4494 31.046 33.3102 31.046H30.4551C30.346 31.044 30.2437 30.9928 30.1766 30.9067L28.7838 25.6318H23.2132ZM28.0876 22.8465C27.6002 20.8967 26.4164 16.7187 25.9986 14.6296C25.6505 16.649 24.6929 20.1307 23.9792 22.8465H28.0876ZM37.4187 24.5873C37.4187 26.9549 38.6025 28.5565 41.179 28.5565C42.1546 28.5853 43.1243 28.3949 44.0166 27.9994C44.1559 27.9298 44.2256 27.9994 44.2256 28.1387V30.3148C44.223 30.4439 44.1736 30.5677 44.0863 30.6629C43.1811 31.1503 41.9973 31.2896 40.6045 31.2896C36.0087 31.2896 34.2678 27.8775 34.2678 24.1172C34.2678 20.0088 36.4439 16.6663 40.1171 16.6663C43.8774 16.6663 45.2004 19.8695 45.2004 22.446C45.2131 23.0526 45.1664 23.659 45.0611 24.2565C45.0567 24.3508 44.9813 24.4262 44.8871 24.4306C43.9167 24.5461 42.9394 24.5927 41.9624 24.5699L37.4187 24.5873ZM40.97 22.2197H42.2757V21.9412C42.2757 20.9488 41.8056 19.208 39.9951 19.208C38.3239 19.208 37.6275 20.6703 37.4186 22.2023L40.97 22.2197Z" fill="#D291FF" />
            </g>
            <g clip-path="url(#clip1_415_18536)">
                <path d="M17.18 28H5.81998C2.60573 28 0 30.6057 0 33.82V45.18C0 48.3943 2.60567 51 5.81998 51H17.18C20.3943 51 23 48.3943 23 45.18V33.82C23 30.6057 20.3943 28 17.18 28Z" fill="#00DDB3" />
                <path d="M17.4819 33.1514C13.5172 33.1514 12.0549 35.978 10.876 38.2495L10.1006 39.7013C8.8505 42.1104 7.91762 43.5739 5.51315 43.5739C5.36373 43.5737 5.21575 43.6029 5.07766 43.66C4.93957 43.7171 4.8141 43.8008 4.70843 43.9065C4.54922 44.0655 4.44079 44.2682 4.39687 44.4888C4.35294 44.7095 4.37551 44.9383 4.4617 45.1462C4.51887 45.2842 4.60271 45.4096 4.70841 45.5152C4.81411 45.6208 4.9396 45.7045 5.0777 45.7615C5.21577 45.8186 5.36374 45.8479 5.51315 45.8477C9.47783 45.8477 10.9401 43.0211 12.119 40.7496L12.8898 39.2978C14.1399 36.8886 15.0728 35.4252 17.4772 35.4252C17.7023 35.4255 17.9223 35.359 18.1095 35.2341C18.2967 35.1092 18.4426 34.9316 18.5287 34.7236C18.6149 34.5158 18.6374 34.287 18.5935 34.0663C18.5496 33.8457 18.4411 33.643 18.2819 33.4839C18.1763 33.3783 18.0508 33.2946 17.9127 33.2375C17.7746 33.1805 17.6266 33.1512 17.4772 33.1514H17.4819Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_415_18536">
                    <rect width="42" height="42" fill="white" transform="translate(11)" />
                </clipPath>
                <clipPath id="clip1_415_18536">
                    <rect width="23" height="23" fill="white" transform="translate(0 28)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default MotionSVG